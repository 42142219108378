// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  return (
    <AppBar
      position="static"
      sx={{
        background: 'none',
        boxShadow: 'none',
        pt: 1, // adds padding to the top of the header
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'center',
          flexDirection: 'column',
          py: 1, // vertical padding for the toolbar
        }}
      >
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img src="/bbashi.png" alt="BBASHI Logo" style={{ height: '25px' }} />
        </Link>
        <Typography variant="subtitle2" sx={{ mt: 1, color: '#fff' }}>
          {t('welcome')}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
