// src/components/Footer.js
import React from 'react';
import { Typography, Link as MuiLink, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box
      component="footer"
      sx={{
        textAlign: 'center',
        p: 1,
        width: '100%',
        background: 'none',
        // On desktop, we want the footer to appear fixed at the bottom.
        // But because our Layout adds bottom padding, it won’t cover the content.
        position: { xs: 'static', md: 'fixed' },
        bottom: { xs: 'auto', md: 0 },
        left: { xs: 'auto', md: 0 },
        zIndex: { xs: 'auto', md: 10 },
      }}
    >
      <MuiLink
        component={Link}
        to="/about"
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          mr: 1,
          '&:hover': { color: '#606C38' },
        }}
      >
        <Typography variant="body2" component="span" sx={{ fontSize: '0.75rem' }}>
          {t('contact')}
        </Typography>
      </MuiLink>
      <MuiLink
        component={Link}
        to="/privacy"
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          '&:hover': { color: '#606C38' },
        }}
      >
        <Typography variant="body2" component="span" sx={{ fontSize: '0.75rem' }}>
          {t('privacy')}
        </Typography>
      </MuiLink>
    </Box>
  );
};

export default Footer;
