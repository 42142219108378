// src/pages/TermsAndConditions.js
import React from 'react';
import { Container, Typography, Link as MuiLink, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledBackLink = styled(MuiLink)({
  fontWeight: 'bold',
  fontSize: '0.8rem',
  display: 'block',
  textAlign: 'center',
  marginBottom: '2rem',
  textDecoration: 'none',
  color: '#ADB5BD',
  '&:hover': { color: '#606C38' },
});

const TermsContainer = styled(Container)({
  marginTop: '1rem',
  marginBottom: '80px',
  padding: '16px',
});

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <TermsContainer maxWidth="md">
      {/* Back Link */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <StyledBackLink component={Link} to="/apps">
          {t('back')}
        </StyledBackLink>
      </Box>
      <Typography
        variant="caption"
        sx={{ fontWeight: 'bold', mb: 2, fontSize: '0.8rem', display: 'block' }}
      >
        {t('termsTitle')}
      </Typography>
      <Typography
        variant="caption"
        sx={{ fontSize: '0.8rem', lineHeight: 1.5, whiteSpace: 'pre-line' }}
      >
        {t('termsContent')}
      </Typography>
    </TermsContainer>
  );
};

export default TermsAndConditions;
