// src/pages/PrintingPage.js
import React, { useState } from 'react';
import { Container, Grid, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, keyframes } from '@mui/material/styles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ImageContainerWithHover = styled('div')({
  textAlign: 'center',
  cursor: 'pointer',
  // Remove grow/scale effect
  '& .hover-text': {
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    fontSize: '0.8rem',
    marginTop: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '0 10px',
    maxWidth: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '&:hover .hover-text': {
    opacity: 1,
  },
});

const ImageWrapper = styled('div')({
  height: '250px', // reduced image container height
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'visible',
});

const ResponsiveImage = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  objectFit: 'contain',
  transition: 'transform 0.7s ease-in-out',
  animation: `${fadeIn} 1s ease forwards`,
});

const ContactLink = (props) => (
  <MuiLink
    href="mailto:contact@bbashi.com"
    sx={{
      textDecoration: 'none',
      color: 'inherit',
      display: 'inline-flex',
      alignItems: 'center',
      '&:hover': { color: '#606C38' },
    }}
    {...props}
  >
    <MailOutlineIcon sx={{ verticalAlign: 'middle', fontSize: '1.2rem', mr: 0.5 }} />
    <Typography variant="caption" component="span">
      {props.children || "Contact us for pricing."}
    </Typography>
  </MuiLink>
);

const PrintingPage = () => {
  const { t } = useTranslation();
  // hovered: 0 for Screen Printing, 1 for Direct To Garment, or null.
  const [hovered, setHovered] = useState(null);

  return (
    <Container sx={{ mt: { xs: '2rem', md: '2rem' }, mb: '80px' }}>
      {/* Back Link placed above the grid */}
      <MuiLink
        component={Link}
        to="/"
        sx={{
          fontWeight: 'bold',
          fontSize: '0.8rem',
          display: 'block',
          textAlign: 'center',
          mb: 9,
          textDecoration: 'none',
          color: '#ADB5BD',
          '&:hover': { color: '#606C38' },
        }}
      >
        {t('back')}
      </MuiLink>
      <Grid container spacing={4} justifyContent="space-evenly" alignItems="center">
        {/* Screen Printing Image */}
        <Grid item xs={12} sm={6} md={4}>
          <div
            onMouseEnter={() => setHovered(0)}
            onMouseLeave={() => setHovered(null)}
            style={{
              opacity: hovered !== null && hovered !== 0 ? 0.5 : 1,
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            <ImageContainerWithHover>
              <ImageWrapper>
                <ResponsiveImage src="/screenprintmachine.png" alt={t('screenPrintingAlt')} />
              </ImageWrapper>
              <div className="hover-text">
                <Typography variant="caption" style={{ fontWeight: 'bold', display: 'block' }}>
                  {t('screenPrinting')}
                </Typography>
                <br />
                <Typography variant="caption" style={{ display: 'block' }}>
                  {t('screenPrintingDesc')}
                  <br /><br />
                  <ContactLink>{t('contactUsForPricing')}</ContactLink>
                </Typography>
              </div>
            </ImageContainerWithHover>
          </div>
        </Grid>
        {/* Direct To Garment (DTG) Image */}
        <Grid item xs={12} sm={6} md={4}>
          <div
            onMouseEnter={() => setHovered(1)}
            onMouseLeave={() => setHovered(null)}
            style={{
              opacity: hovered !== null && hovered !== 1 ? 0.5 : 1,
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            <ImageContainerWithHover>
              <ImageWrapper>
                <ResponsiveImage src="/dtg.png" alt={t('directToGarmentAlt')} />
              </ImageWrapper>
              <div className="hover-text">
                <Typography variant="caption" style={{ fontWeight: 'bold', display: 'block' }}>
                  {t('directToGarment')}
                </Typography>
                <br />
                <Typography variant="caption" style={{ display: 'block' }}>
                  {t('directToGarmentDesc')}
                  <br /><br />
                  <ContactLink>{t('contactUsForPricing')}</ContactLink>
                </Typography>
              </div>
            </ImageContainerWithHover>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrintingPage;
