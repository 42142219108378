import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import PrintingPage from './pages/PrintingPage';
import AppsPage from './pages/AppsPage';
import PrivacyPage from './pages/PrivacyPage';
import AboutPage from './pages/AboutPage';
import InventusApp from './pages/InventusApp';
import TermsAndConditions from './pages/TermsAndConditions';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="printing" element={<PrintingPage />} />
          <Route path="apps" element={<AppsPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="/inventusapp" element={<InventusApp />} />
          <Route path="/terms" element={<TermsAndConditions />} />

        </Route>
      </Routes>
    </Router>
  );
}

export default App;
