// src/components/Layout.js
import React, { useContext } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import { CookieConsentProvider, CookieConsentContext } from '../CookieConsentContext';
import { useTranslation } from 'react-i18next';

const LayoutContent = () => {
  const { t } = useTranslation();
  const { setAccepted } = useContext(CookieConsentContext);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      {/* 
          The main content area uses flex:1 to fill available space.
          The paddingBottom ensures that if the content is short, there’s always room
          for the fixed footer without overlapping content.
      */}
      <main style={{ flex: 1, paddingBottom: '60px' }}>
        <Outlet />
      </main>
      <CookieConsent
        location="bottom"
        buttonText={t('cookieButtonText')}
        cookieName="bbashiCookieConsent"
        style={{ background: "#000", fontSize: "0.8rem", color: "#fff" }}
        buttonStyle={{ backgroundColor: "#000", color: "#fff", fontSize: "0.8rem" }}
        expires={150}
        onAccept={() => setAccepted(true)}
      >
        {t('cookieBannerText')}
      </CookieConsent>
      <Footer />
    </div>
  );
};

const Layout = () => (
  <CookieConsentProvider>
    <LayoutContent />
  </CookieConsentProvider>
);

export default Layout;
