// src/pages/Home.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, keyframes } from '@mui/material/styles';
import { CookieConsentContext } from '../CookieConsentContext';
import { useTranslation } from 'react-i18next';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ImageContainer = styled('div')({
  textAlign: 'center',
  cursor: 'pointer',
  position: 'relative', // required for overlay positioning
  transition: 'opacity 0.3s ease-in-out',
  '& .hover-text': {
    opacity: 0,
    transition: 'opacity 0.1s ease-in-out',
    fontSize: '0.8rem',
    marginTop: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%',
  },
  '&:hover .hover-text': {
    opacity: 1,
  },
});

const ResponsivePrintingImage = styled('img')(({ theme }) => ({
  height: '500px',
  objectFit: 'contain',
  [theme.breakpoints.down('sm')]: {
    height: '500px',
  },
}));

// Monitor container maintaining a fixed aspect ratio.
const MonitorWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  aspectRatio: '16/9', // adjust to match your monitor image's aspect ratio
}));

const ResponsiveDisplayImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

const Home = () => {
  const { t } = useTranslation();
  const { accepted } = useContext(CookieConsentContext);
  const [hovered, setHovered] = useState(null);
  const [printingImageSrc, setPrintingImageSrc] = useState('/printing.webp');

  // For video overlay on the monitor
  const [videoOn, setVideoOn] = useState(false);
  const [videoSrc, setVideoSrc] = useState("/screensaver.mp4");
  const [videoOpacity, setVideoOpacity] = useState(1);
  const videoRef = useRef(null);

  // Update printing image based on cookie consent.
  useEffect(() => {
    const cookiePresent = document.cookie.indexOf("bbashiCookieConsent") !== -1;
    if (accepted || cookiePresent) {
      setPrintingImageSrc('/printing.webp');
    } else {
      setPrintingImageSrc('/printing2.png');
    }
  }, [accepted]);

  // Magic marker effect for printing image
  const canvasRef = useRef(null);
  const prevPointRef = useRef({ x: null, y: null });
  const handleMarkerMouseMove = (e) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const ctx = canvas.getContext('2d');
    ctx.strokeStyle = '#FF0000';
    ctx.lineWidth = 3;
    ctx.lineCap = 'round';
    if (prevPointRef.current.x !== null && prevPointRef.current.y !== null) {
      ctx.beginPath();
      ctx.moveTo(prevPointRef.current.x, prevPointRef.current.y);
      ctx.lineTo(x, y);
      ctx.stroke();
    }
    prevPointRef.current = { x, y };
  };

  useEffect(() => {
    let animationFrameId;
    const fadeLoop = () => {
      const canvas = canvasRef.current;
      if (canvas) {
        const ctx = canvas.getContext('2d');
        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillStyle = 'rgba(0, 0, 0, 0.01)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const gradient = ctx.createRadialGradient(
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 5,
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 2
        );
        gradient.addColorStop(0, 'rgba(0,0,0,0)');
        gradient.addColorStop(1, 'rgba(0,0,0,0.1)');
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.globalCompositeOperation = 'source-over';
      }
      animationFrameId = requestAnimationFrame(fadeLoop);
    };
    fadeLoop();
    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  const handleMarkerMouseLeave = () => {
    prevPointRef.current = { x: null, y: null };
  };

  // Handlers for video overlay on the monitor
  const handleVideoMouseEnter = () => {
    // Fade out current video, then switch source to desc.mp4.
    setVideoOpacity(0);
    setTimeout(() => {
      setVideoSrc("/desc.mp4");
    }, 500);
    setVideoOn(true);
  };

  const handleVideoMouseLeave = () => {
    // Fade out current video, then switch back to screensaver.mp4.
    setVideoOpacity(0);
    setTimeout(() => {
      setVideoSrc("/screensaver.mp4");
    }, 500);
    setVideoOn(false);
  };

  // onLoadedData handler to automatically play video and set opacity back to 1
  const handleVideoLoadedData = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
    setVideoOpacity(1);
  };

  return (
    <Container sx={{ flex: 1, mt: { xs: '2rem', md: '5rem' }, pb: { xs: '80px', md: '0px' } }}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {/* Printing Image with magic marker effect */}
        <Grid item xs={12} sm={6} md={4}>
          <Link
            to="/printing"
            style={{ textDecoration: 'none', color: 'inherit' }}
            onMouseEnter={() => setHovered(0)}
            onMouseLeave={() => {
              setHovered(null);
              handleMarkerMouseLeave();
            }}
            onMouseMove={handleMarkerMouseMove}
          >
            <ImageContainer style={{ opacity: hovered !== null && hovered !== 0 ? 0.5 : 1 }}>
              <ResponsivePrintingImage src={printingImageSrc} alt={t('printingAlt')} />
              <canvas
                ref={canvasRef}
                width={85}
                height={105}
                style={{
                  position: 'absolute',
                  top: 120,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  pointerEvents: 'none',
                }}
              />
              <div className="hover-text">
                <Typography variant="caption">{t('screenPrinting')}</Typography>
              </div>
            </ImageContainer>
          </Link>
        </Grid>
        {/* Monitor with Video Overlay for Apps section */}
        <Grid item xs={12} sm={6} md={4}>
          <Link
            to="/apps"
            style={{ textDecoration: 'none', color: 'inherit' }}
            onMouseEnter={handleVideoMouseEnter}
            onMouseLeave={handleVideoMouseLeave}
          >
            <ImageContainer style={{ opacity: hovered !== null && hovered !== 1 ? 0.5 : 1 }}>
              <MonitorWrapper>
                <ResponsiveDisplayImage src="/display.png" alt={t('appsAlt')} />
                <Box
                  component="video"
                  ref={videoRef}
                  src={videoSrc}
                  autoPlay
                  muted
                  loop
                  playsInline
                  preload="auto"
                  onLoadedData={handleVideoLoadedData}
                  sx={{
                    position: 'absolute',
                    top: '3%',       // adjust as needed
                    left: '15.6%',   // adjust as needed
                    width: '70%',    // responsive width (adjust as needed)
                    height: '68%',   // responsive height (adjust as needed)
                    objectFit: 'cover',
                    pointerEvents: 'none',
                    opacity: videoOpacity,
                    transition: 'opacity 0.5s ease-in-out',
                    transform: 'none',
                  }}
                />
              </MonitorWrapper>
              <div className="hover-text">
                <Typography variant="caption">
                  <br />
                  {t('appDevelopment')}
                </Typography>
              </div>
            </ImageContainer>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
