// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      back: "Back",
      welcome: "Welcome to BBASHI",
      // Home page keys
      screenPrinting: "Screen Printing & DTG",
      printingAlt: "Printing Image",
      appDevelopment: "Web & Mobile Development",
      inventusApp: "Inventus App",
      inventusAppDesc:
        "Inventus App is a simple and intuitive inventory management solution designed for individuals and small businesses. Whether you're tracking personal belongings, office supplies, or business inventory, Inventus App keeps everything organized—without compromising your privacy.",
      availableSoonOn: "Available now on",
      appStoreAlt: "App Store",
      // New keys for Apps page dev support section:
      appDevService: "App Development Service",
      appDevServiceDesc:
        "We deliver rapid development with thoughtful design and a user-centric experience for both web and mobile. Our solutions are innovative, reliable, and built to scale.",
      appsAlt: "Apps Image",
      contactUsForPricing: "Contact Us For Pricing",
      // Printing page keys
      screenPrintingAlt: "Screen Print Machine Image",
      screenPrintingDesc: "We offer 1 print colour service, with a minimum order of 10 prints.",
      directToGarment: "Direct To Garment",
      directToGarmentDesc: "We offer full digital colour printing, with no minimum orders.",
      directToGarmentAlt: "Direct To Garment Image",
      // Inventus App page keys
      inventusAppAlt: "Inventus App Image",
      // Terms and Conditions / EULA keys
      termsAndConditionsLink: "Terms & Conditions",
      privacyPolicyTitle: "Privacy Policy",
      privacyPolicyContent: `Last Updated: [Date]

1. Introduction
Welcome to Inventus App by BBASHI GmbH. We respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and disclose data when you use our app or services.

2. Information We Collect

Personal Information: We may collect data like your name, email address, or payment details if you create an account or purchase a subscription.
Usage Data: We collect anonymous usage statistics such as app session length, feature usage, and crash logs to improve app performance.
Device Data: We may collect device-specific information (e.g., OS version, device model) to ensure app compatibility and troubleshoot bugs.

3. How We Use Your Data

To Provide and Maintain Our Service: We use your information to deliver the core functionality of our app, including customer support.
Subscription Management: If you purchase a subscription, we use necessary data to manage billing and renewal.
Analytics and Improvements: We analyze usage patterns to enhance features, fix bugs, and improve overall user experience.
Communications: We may occasionally send you updates or notifications related to the app or subscriptions, if permitted by law.

4. Data Sharing and Disclosure
We do not sell your data. We may share data with:
• Service Providers: Trusted third parties assisting in analytics, payment processing, or hosting.
• Legal Requirements: If required by law or to protect our rights/users (e.g., responding to lawful requests, preventing fraud).

5. Data Retention
We keep your personal information only as long as necessary for the purposes described in this policy or as required by law.

6. Your Rights

Access/Update: You may contact us to update or correct your information.
Delete: You can request account deletion or erasure of personal data, subject to legal obligations.

7. Security
We use commercially reasonable measures to protect your data. However, no method of transmission or storage is 100% secure.

8. Children's Privacy
Our service is not intended for children under 13. We do not knowingly collect personal data from children without parental consent.

9. Changes to This Policy
We may update this Privacy Policy periodically. We’ll notify you of significant changes by posting a new version and updating the “Last Updated” date.

10. Contact Us
If you have questions about this Privacy Policy, please contact us at:
BBASHI GmbH / Inventus App
contact@bbashi.com`,
      termsTitle: "Terms of Use (EULA)",
      termsContent: `Last Updated: 16-02-24

These Terms of Use (“Terms”) govern your use of Inventus App provided by BBASHI GmbH (“we,” “us,” or “our”). By downloading or using the App, you agree to be bound by these Terms.

1. License Grant
We grant you a limited, non-exclusive, non-transferable license to use Inventus App for personal, non-commercial purposes in accordance with these Terms and the App Store Terms of Service.

2. Auto-Renewable Subscription
Subscription Name & Length: We offer an auto-renewing subscription called “Inventus Premium” for 1 year.
Price & Billing: The subscription costs $4.99 (plus any applicable taxes). Your iTunes Account will be charged upon confirmation of purchase and at renewal.
Auto-Renewal: Subscriptions automatically renew unless turned off at least 24 hours before the current period ends. Your account will be charged for renewal within 24 hours prior to the end of the current period, at the same cost unless otherwise noted.
Manage Subscriptions: You can manage or cancel your subscription in your iTunes Account settings at any time.
Free Trials (if applicable): If we offer a free trial, unused portions are forfeited upon purchasing a subscription.

3. User Conduct
You agree not to use Inventus App for any unlawful or harmful purpose. You must comply with all applicable local, state, national, and international laws.

4. Intellectual Property
All rights, title, and interest in Inventus App, including content, features, and trademarks, belong to BBASHI GmbH or its licensors. You may not modify, redistribute, or create derivative works based on the App.

5. Privacy
Your use of Inventus App is also subject to our Privacy Policy, available on our website.

6. Disclaimer of Warranties
Inventus App is provided “as is.” We disclaim all warranties, express or implied, including merchantability, fitness for a particular purpose, and non-infringement.

7. Limitation of Liability
To the maximum extent permitted by law, in no event shall BBASHI GmbH be liable for indirect, incidental, or consequential damages arising from the use or inability to use Inventus App.

8. Changes and Termination
We may modify or discontinue Inventus App at any time. Continued use after changes become effective constitutes acceptance.

9. Governing Law
These Terms are governed by the laws of Switzerland, without regard to conflict of law principles.

10. Contact Us
If you have any questions about these Terms, please contact us at:
BBASHI GmbH / Inventus App
contact@bbashi.com`,
      // Contact page keys
      ourHeadquarters: "Our Headquarters",
      address: "Loëstrasse 37A, 7000 Chur",
      headquartersAlt: "Headquarters Image",
      contact: "Contact",
      privacy: "Privacy",
      privacyContent:
        "At BBASHI, your privacy is our priority. This applies to our Inventus App and all information provided on our website. We do not collect, store, or share any personal data through Inventus App or on this site. All data remains solely on your device and is not transmitted to our servers. We also use cookies solely to enhance your browsing experience and ensure our site functions properly. By continuing to use our website, you consent to the use of cookies as described in our policy.",
      cookieButtonText: "I Understand",
      cookieBannerText: "This website uses cookies to enhance your browsing experience."
    }
  },
  de: {
    translation: {
      back: "Zurück",
      welcome: "Willkommen bei BBASHI",
      screenPrinting: "Siebdruck & DTG",
      printingAlt: "Druckbild",
      inventusApp: "Inventus App",
      inventusAppDesc:
        "Inventus App ist eine einfache und intuitive Lösung zur Inventarverwaltung, die für Einzelpersonen und kleine Unternehmen entwickelt wurde. Egal, ob Sie persönliche Gegenstände, Bürobedarf oder Geschäftsbestände verfolgen – Inventus App hält alles organisiert, ohne Ihre Privatsphäre zu gefährden.",
      availableSoonOn: "Ab sofort verfügbar",
      appStoreAlt: "App Store",
      appDevelopment: "Web & Mobile-Entwicklung",
contactUsForPricing: "Kontaktieren Sie uns für Preise",

      appDevService: "App-Entwicklungsdienst",
appDevServiceDesc: "Wir bieten eine schnelle Entwicklung mit durchdachtem Design und einer benutzerzentrierten Erfahrung für Web und Mobile. Unsere Lösungen sind innovativ, zuverlässig und skalierbar.",
      appsAlt: "App-Bild",
      screenPrintingAlt: "Bild der Siebdruckmaschine",
      screenPrintingDesc: "Wir bieten einen Druckdienst mit einer Farboption und einer Mindestbestellmenge von 10 Drucken.",
      directToGarment: "Direct To Garment",
      directToGarmentDesc: "Wir bieten vollfarbigen digitalen Druck ohne Mindestbestellmenge.",
      directToGarmentAlt: "Bild Direct To Garment",
      inventusAppAlt: "Inventus App Bild",
      termsAndConditionsLink: "AGB & Nutzungsbedingungen",
      privacyPolicyTitle: "Datenschutzerklärung",
      privacyPolicyContent: `Zuletzt aktualisiert: [Date]

1. Einführung
Willkommen bei Inventus App von BBASHI GmbH. Wir respektieren Ihre Privatsphäre und verpflichten uns, Ihre persönlichen Daten zu schützen. Diese Datenschutzerklärung beschreibt, wie wir Daten erheben, verwenden und weitergeben, wenn Sie unsere App oder Dienstleistungen nutzen.

2. Informationen, die wir erheben

Persönliche Informationen: Wir können Daten wie Ihren Namen, Ihre E-Mail-Adresse oder Zahlungsinformationen erheben, wenn Sie ein Konto erstellen oder ein Abonnement abschließen.
Nutzungsdaten: Wir sammeln anonyme Nutzungsstatistiken wie Sitzungsdauer, Funktionsnutzung und Absturzprotokolle zur Verbesserung der App-Leistung.
Gerätedaten: Wir können gerätespezifische Informationen (z. B. Betriebssystemversion, Gerätemodell) erheben, um die Kompatibilität der App sicherzustellen und Fehler zu beheben.

3. Wie wir Ihre Daten verwenden

Bereitstellung und Wartung unseres Dienstes: Wir nutzen Ihre Daten, um die Kernfunktionen unserer App bereitzustellen, einschließlich Kundensupport.
Abonnementverwaltung: Wenn Sie ein Abonnement abschließen, nutzen wir die notwendigen Daten zur Verwaltung von Rechnungen und Verlängerungen.
Analysen und Verbesserungen: Wir analysieren Nutzungsdaten, um Funktionen zu verbessern, Fehler zu beheben und die Benutzererfahrung zu optimieren.
Kommunikation: Wir können Ihnen gelegentlich Updates oder Benachrichtigungen zur App oder zu Abonnements senden, sofern gesetzlich zulässig.

4. Datenweitergabe und Offenlegung
Wir verkaufen Ihre Daten nicht. Wir können Ihre Daten teilen mit:
• Dienstleistern: Vertrauenswürdigen Dritten, die uns bei Analysen, der Zahlungsabwicklung oder beim Hosting unterstützen.
• Gesetzlichen Anforderungen: Wenn es gesetzlich vorgeschrieben ist oder zum Schutz unserer Rechte/Nutzer (z. B. bei rechtmäßigen Anfragen oder zur Betrugsprävention).

5. Datenspeicherung
Wir speichern Ihre persönlichen Daten nur so lange, wie es für die in dieser Erklärung beschriebenen Zwecke oder gesetzlich erforderlich ist.

6. Ihre Rechte
Zugriff/Aktualisierung: Sie können uns kontaktieren, um Ihre Daten zu aktualisieren oder zu korrigieren.
Löschung: Sie können die Löschung Ihres Kontos oder die Entfernung Ihrer persönlichen Daten beantragen, vorbehaltlich gesetzlicher Vorgaben.

7. Sicherheit
Wir setzen wirtschaftlich angemessene Maßnahmen zum Schutz Ihrer Daten ein. Dennoch ist keine Übertragungs- oder Speichermethode 100 % sicher.

8. Datenschutz von Kindern
Unsere Dienste sind nicht für Kinder unter 13 Jahren bestimmt. Wir erheben wissentlich keine persönlichen Daten von Kindern ohne elterliche Zustimmung.

9. Änderungen dieser Erklärung
Wir können diese Datenschutzerklärung gelegentlich aktualisieren. Wichtige Änderungen werden durch die Veröffentlichung einer neuen Version und Aktualisierung des Datums mitgeteilt.

10. Kontakt
Bei Fragen zu dieser Datenschutzerklärung kontaktieren Sie uns bitte:
BBASHI GmbH / Inventus App
contact@bbashi.com`,
      termsTitle: "Nutzungsbedingungen (EULA)",
      termsContent: `Zuletzt aktualisiert: 16-02-24

Diese Nutzungsbedingungen („Bedingungen“) regeln Ihre Nutzung von Inventus App, bereitgestellt von BBASHI GmbH („wir“, „uns“ oder „unser“). Durch das Herunterladen oder die Nutzung der App stimmen Sie diesen Bedingungen zu.

1. Lizenzgewährung
Wir gewähren Ihnen eine eingeschränkte, nicht-exklusive, nicht übertragbare Lizenz zur Nutzung von Inventus App für private, nicht-kommerzielle Zwecke gemäß diesen Bedingungen und den Bedingungen des App Stores.

2. Automatisch verlängerndes Abonnement
Name und Dauer des Abonnements: Wir bieten ein Abonnement mit automatischer Verlängerung namens „Inventus Premium“ für 1 Jahr an.Preis & Abrechnung: Das Abonnement kostet 4,99 $ (zzgl. anfallender Steuern). Ihr iTunes-Konto wird bei Bestätigung des Kaufs und bei Verlängerung belastet.
Renouvellement automatique: Abonnements verlängern sich automatisch, sofern sie nicht mindestens 24 Stunden vor Ablauf der aktuellen Periode deaktiviert werden. Ihr Konto wird innerhalb von 24 Stunden vor Ende der Periode zum gleichen Preis belastet, sofern nicht anders vermerkt.
Verwaltung von Abonnements: Sie können Ihr Abonnement jederzeit in den Einstellungen Ihres iTunes-Kontos verwalten oder kündigen.
Kostenlose Testphasen (falls zutreffend): Falls wir eine kostenlose Testphase anbieten, verfällt der ungenutzte Teil bei Abschluss eines Abonnements.

3. Nutzerverhalten
Sie verpflichten sich, Inventus App nicht für rechtswidrige oder schädliche Zwecke zu verwenden und alle geltenden Gesetze einzuhalten.

4. Geistiges Eigentum
Alle Rechte, Titel und Interessen an Inventus App, einschließlich Inhalte, Funktionen und Marken, gehören BBASHI GmbH oder deren Lizenzgebern. Eine Modifikation, Weitergabe oder Erstellung abgeleiteter Werke ist untersagt.

5. Datenschutz
Ihre Nutzung von Inventus App unterliegt auch unserer Datenschutzerklärung, die auf unserer Website verfügbar ist.

6. Ausschluss von Garantien
Inventus App wird „wie besehen“ bereitgestellt. Wir schließen alle ausdrücklichen oder stillschweigenden Garantien, einschließlich der Eignung für einen bestimmten Zweck, aus.

7. Haftungsbeschränkung
Soweit gesetzlich zulässig, haftet BBASHI GmbH nicht für indirekte, zufällige oder Folgeschäden, die aus der Nutzung oder der Unmöglichkeit der Nutzung von Inventus App resultieren.

8. Änderungen und Beendigung
Wir können Inventus App jederzeit ändern oder einstellen. Die fortgesetzte Nutzung nach Inkrafttreten von Änderungen gilt als Zustimmung.

9. Anwendbares Recht
Diese Bedingungen unterliegen den Gesetzen von [Ihr Land], ohne Rücksicht auf Kollisionsnormen.

10. Kontakt
Bei Fragen zu diesen Bedingungen kontaktieren Sie uns bitte:
BBASHI GmbH / Inventus App
contact@bbashi.com`,
      ourHeadquarters: "Unser Hauptsitz",
      address: "Loëstrasse 37A, 7000 Chur",
      headquartersAlt: "Headquarters Image",
      contact: "Kontakt",
      privacy: "Privatsphäre",
      privacyContent:
        "Bei BBASHI hat Ihre Privatsphäre höchste Priorität. Dies gilt für unsere Inventus-App und alle auf unserer Website bereitgestellten Informationen. Wir sammeln, speichern oder geben keine personenbezogenen Daten über die Inventus-App oder diese Website weiter. Alle Daten verbleiben ausschließlich auf Ihrem Gerät und werden nicht an unsere Server übertragen. Wir verwenden Cookies außerdem ausschließlich, um Ihr Surferlebnis zu verbessern und sicherzustellen, dass unsere Website ordnungsgemäß funktioniert. Indem Sie unsere Website weiterhin nutzen, stimmen Sie der Verwendung von Cookies zu, wie in unseren Richtlinien beschrieben.",
      cookieButtonText: "Ich verstehe",
      cookieBannerText: "Diese Website verwendet Cookies, um Ihr Surf-Erlebnis zu verbessern."
    }
  },
  fr: {
    translation: {
      back: "Retour",
      welcome: "Bienvenue chez BBASHI",
      screenPrinting: "Impression par Sérigraphie & DTG",
      printingAlt: "Image d'impression",
      inventusApp: "Inventus App",
      inventusAppDesc:
        "Inventus App est une solution simple et intuitive de gestion d'inventaire conçue pour les particuliers et les petites entreprises. Que vous suiviez vos biens personnels, vos fournitures de bureau ou votre inventaire professionnel, Inventus App garde tout organisé—sans compromettre votre confidentialité.",
      availableSoonOn: "Disponible dès maintenant sur",
      contactUsForPricing: "Contactez-nous pour les tarifs",

      appStoreAlt: "App Store",
appDevService: "Service de développement d'applications",
appDevServiceDesc: "Nous assurons un développement rapide avec un design réfléchi et une expérience utilisateur centrée, tant pour le web que pour le mobile. Nos solutions sont innovantes, fiables et évolutives.",
      appDevelopment: "Développement Web & Mobile",
      appsAlt: "Image d'applications",
      screenPrintingAlt: "Image de la machine d'impression",
      screenPrintingDesc: "Nous proposons un service d'impression avec une seule couleur, avec une commande minimale de 10 exemplaires.",
      directToGarment: "Direct To Garment",
      directToGarmentDesc: "Nous proposons une impression numérique en couleur sans quantité minimale.",
      directToGarmentAlt: "Image Direct To Garment",
      inventusAppAlt: "Image Inventus App",
      termsAndConditionsLink: "Conditions Générales",
      privacyPolicyTitle: "Politique de Confidentialité",
      privacyPolicyContent: `Dernière mise à jour : [Date]

1. Introduction
Bienvenue sur Inventus App de BBASHI GmbH. Nous respectons votre vie privée et nous engageons à protéger vos informations personnelles. Cette politique de confidentialité décrit comment nous collectons, utilisons et divulguons vos données lorsque vous utilisez notre application ou nos services.

2. Informations que nous collectons

Informations personnelles : Nous pouvons collecter des données telles que votre nom, votre adresse e-mail ou des informations de paiement si vous créez un compte ou souscrivez à un abonnement.
Données d'utilisation : Nous recueillons des statistiques d'utilisation anonymes telles que la durée des sessions, l'utilisation des fonctionnalités et les rapports de crash pour améliorer la performance de l'application.
Données du dispositif : Nous pouvons collecter des informations spécifiques au dispositif (par exemple, la version du système d'exploitation, le modèle du dispositif) afin d'assurer la compatibilité de l'application et de résoudre les problèmes.

3. Comment nous utilisons vos données

Pour fournir et maintenir notre service : Nous utilisons vos informations pour offrir les fonctionnalités essentielles de notre application, y compris le support client.
Gestion des abonnements : Si vous souscrivez à un abonnement, nous utilisons les données nécessaires pour gérer la facturation et le renouvellement.
Analyses et améliorations : Nous analysons les données d'utilisation pour améliorer les fonctionnalités, corriger les bugs et optimiser l'expérience utilisateur.
Communications : Nous pouvons vous envoyer occasionnellement des mises à jour ou des notifications relatives à l'application ou aux abonnements, si la loi le permet.

4. Partage et divulgation des données
Nous ne vendons pas vos données. Nous pouvons partager vos données avec :

• Fournisseurs de services : Des tiers de confiance qui nous assistent dans l'analyse, le traitement des paiements ou l'hébergement.
• Exigences légales : Si la loi l'exige ou pour protéger nos droits/utilisateurs (par exemple, répondre à des demandes légales ou prévenir la fraude).

5. Conservation des données
Nous conservons vos informations personnelles uniquement aussi longtemps que nécessaire aux fins décrites dans cette politique ou conformément à la loi.

6. Vos droits

Accès/Mise à jour : Vous pouvez nous contacter pour mettre à jour ou corriger vos informations.
Suppression : Vous pouvez demander la suppression de votre compte ou l'effacement de vos données personnelles, sous réserve des obligations légales.

7. Sécurité
Nous utilisons des mesures raisonnables pour protéger vos données. Cependant, aucune méthode de transmission ou de stockage n'est garantie à 100 %.

8. Protection des enfants
Notre service n'est pas destiné aux enfants de moins de 13 ans. Nous ne collectons pas sciemment de données personnelles auprès des enfants sans le consentement parental.

9. Modifications de cette politique
Nous pouvons mettre à jour cette politique de confidentialité périodiquement. Les modifications importantes seront communiquées par la publication d'une nouvelle version et la mise à jour de la date.

10. Contact
Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à :
BBASHI GmbH / Inventus App
contact@bbashi.com`,
      termsTitle: "Conditions d'Utilisation (EULA)",
      termsContent: `Dernière mise à jour : 16-02-24

Ces Conditions d'utilisation (« Conditions ») régissent votre utilisation de Inventus App fourni par BBASHI GmbH (« nous », « notre »). En téléchargeant ou en utilisant l'application, vous acceptez d'être lié par ces Conditions.

1. Attribution de licence
Nous vous accordons une licence limitée, non exclusive et non transférable pour utiliser Inventus App à des fins personnelles et non commerciales conformément à ces Conditions et aux conditions de l'App Store.

2. Abonnement renouvelable automatiquement
Nom et durée de l'abonnement : Nous proposons un abonnement à renouvellement automatique appelé « Inventus Premium » pour 1 an.Prix et facturation : L'abonnement coûte 4,99 $ (plus les taxes applicables). Votre compte iTunes sera débité lors de la confirmation de l'achat et lors du renouvellement.
Renouvellement automatique : Les abonnements se renouvellent automatiquement, sauf s'ils sont désactivés au moins 24 heures avant la fin de la période en cours. Votre compte sera débité pour le renouvellement dans les 24 heures précédant la fin de la période, au même tarif, sauf indication contraire.
Gestion des abonnements : Vous pouvez gérer ou annuler votre abonnement dans les paramètres de votre compte iTunes à tout moment.
Proves gratuites (si applicable) : Si nous offrons une période d'essai gratuite, la portion non utilisée sera perdue lors de l'achat d'un abonnement.

3. Conduite de l'utilisateur
Vous acceptez de ne pas utiliser Inventus App à des fins illicites ou nuisibles et de respecter toutes les lois applicables au niveau local, régional, national et international.

4. Propriété intellectuelle
Tous les droits, titres et intérêts relatifs à Inventus App, y compris le contenu, les fonctionnalités et les marques, appartiennent à BBASHI GmbH ou à ses concédants de licence. Vous n'êtes pas autorisé à modifier, redistribuer ou créer des œuvres dérivées basées sur l'application.

5. Confidentialité
L'utilisation de Inventus App est également soumise à notre politique de confidentialité, disponible sur notre site web.

6. Exclusion de garanties
Inventus App est fournie « telle quelle ». Nous déclinons toute garantie, expresse ou implicite, y compris celle de l'adéquation à un usage particulier.

7. Limitation de responsabilité
Dans la mesure permise par la loi, BBASHI GmbH ne pourra être tenu responsable des dommages indirects, accessoires ou consécutifs résultant de l'utilisation ou de l'incapacité d'utiliser Inventus App.

8. Modifications et cessation
Nous pouvons modifier ou interrompre Inventus App à tout moment. L'utilisation continue après l'entrée en vigueur des modifications vaut acceptation.

9. Droit applicable
Ces Conditions sont régies par les lois de [Votre pays], sans égard aux principes de conflit de lois.

10. Contact
Pour toute question concernant ces Conditions, veuillez nous contacter à :
BBASHI GmbH / Inventus App
contact@bbashi.com`,
  ourHeadquarters: "Notre siège",
  address: "Loëstrasse 37A, 7000 Chur",
  headquartersAlt: "Image du siège",
  contact: "Contact",
  privacy: "Confidentialité",
  privacyContent: "Chez BBASHI, votre confidentialité est notre priorité. Cela s'applique à notre Inventus App et à toutes les informations fournies sur notre site Web. Nous ne collectons, ne stockons ni ne partageons aucune donnée personnelle via Inventus App ou sur ce site. Toutes les données restent exclusivement sur votre appareil et ne sont pas transmises à nos serveurs. Nous utilisons également des cookies uniquement pour améliorer votre expérience de navigation et garantir le bon fonctionnement de notre site. En continuant à utiliser notre site, vous consentez à l'utilisation des cookies comme décrit dans notre politique.",
  cookieButtonText: "Je comprends",
  cookieBannerText: "Ce site utilise des cookies pour améliorer votre expérience de navigation."
  }
  },
  it: {
    translation: {
      back: "Indietro",
      welcome: "Benvenuto su BBASHI",
      screenPrinting: "Stampa serigrafica & DTG",
      printingAlt: "Immagine di stampa",
      inventusApp: "Inventus App",
      contactUsForPricing: "Contattaci per i prezzi",

      inventusAppDesc:
        "Inventus App è una soluzione semplice e intuitiva per la gestione dell'inventario, progettata per privati e piccole imprese. Che tu stia monitorando beni personali, forniture per ufficio o l'inventario aziendale, Inventus App mantiene tutto organizzato senza compromettere la tua privacy.",
      availableSoonOn: "Disponibile ora su",
      appStoreAlt: "App Store",

      appDevService: "Servizio di sviluppo applicazioni",
appDevServiceDesc: "Offriamo uno sviluppo rapido con un design attento e un'esperienza utente incentrata sia sul web che sul mobile. Le nostre soluzioni sono innovative, affidabili e scalabili.",
      appDevelopment: "Sviluppo Web & Mobile",
      appsAlt: "Immagine delle app",
      screenPrintingAlt: "Immagine della macchina serigrafica",
      screenPrintingDesc: "Offriamo un servizio di stampa a un solo colore, con un ordine minimo di 10 stampe.",
      directToGarment: "Stampa diretta su tessuto",
      directToGarmentDesc: "Offriamo stampa digitale a colori completa, senza quantità minima d'ordine.",
      directToGarmentAlt: "Immagine di stampa diretta su tessuto",
      inventusAppAlt: "Immagine Inventus App",
      termsAndConditionsLink: "Termini e Condizioni",
      privacyPolicyTitle: "Informativa sulla Privacy",
      privacyPolicyContent: `Ultimo aggiornamento: [Data]

1. Introduzione
Benvenuto su Inventus App di BBASHI GmbH. Rispettiamo la tua privacy e ci impegniamo a proteggere le tue informazioni personali. La presente Informativa sulla Privacy descrive come raccogliamo, utilizziamo e divulghiamo i dati quando utilizzi la nostra app o i nostri servizi.

2. Informazioni raccolte

Informazioni personali: Potremmo raccogliere dati come nome, indirizzo email o dettagli di pagamento se crei un account o acquisti un abbonamento.
Dati di utilizzo: Raccogliamo statistiche anonime sull'utilizzo, come la durata della sessione, l'uso delle funzionalità e i report di crash, per migliorare le prestazioni dell'app.
Dati del dispositivo: Potremmo raccogliere informazioni specifiche del dispositivo (es. versione del sistema operativo, modello del dispositivo) per garantire la compatibilità dell'app e risolvere eventuali problemi.

3. Modalità di utilizzo dei dati

Per fornire e mantenere il nostro servizio: Utilizziamo le tue informazioni per offrire le funzionalità essenziali della nostra app, incluso il supporto clienti.
Gestione degli abbonamenti: Se acquisti un abbonamento, utilizziamo i dati necessari per gestire la fatturazione e il rinnovo.
Analisi e miglioramenti: Analizziamo i dati di utilizzo per migliorare le funzionalità, correggere bug e ottimizzare l'esperienza utente.
Comunicazioni: Potremmo inviarti aggiornamenti o notifiche relative all'app o agli abbonamenti, se previsto dalla legge.

4. Condivisione e divulgazione dei dati
Non vendiamo i tuoi dati. Possiamo condividerli con:

• Fornitori di servizi: Terze parti di fiducia che ci assistono nelle analisi, nel trattamento dei pagamenti o nell'hosting.
• Requisiti legali: Se richiesto dalla legge o per proteggere i nostri diritti/utenti (es. rispondere a richieste legali, prevenire frodi).

5. Conservazione dei dati
Conserviamo le tue informazioni personali solo per il tempo necessario alle finalità descritte in questa informativa o secondo quanto previsto dalla legge.

6. I tuoi diritti

Accesso/Aggiornamento: Puoi contattarci per aggiornare o correggere le tue informazioni.
Cancellazione: Puoi richiedere la cancellazione del tuo account o la rimozione dei tuoi dati personali, nel rispetto delle normative vigenti.

7. Sicurezza
Adottiamo misure ragionevoli per proteggere i tuoi dati. Tuttavia, nessun metodo di trasmissione o conservazione è garantito al 100 %.

8. Privacy dei minori
Il nostro servizio non è destinato ai bambini al di sotto dei 13 anni. Non raccogliamo consapevolmente dati personali dai bambini senza il consenso dei genitori.

9. Modifiche all'Informativa
Potremmo aggiornare periodicamente questa Informativa sulla Privacy. Le modifiche rilevanti saranno comunicate mediante la pubblicazione di una nuova versione e l'aggiornamento della data.

10. Contattaci
Per eventuali domande su questa Informativa, contattaci:
BBASHI GmbH / Inventus App
contact@bbashi.com`,
      termsTitle: "Termini di Utilizzo (EULA)",
      termsContent: `Ultimo aggiornamento: 16-02-24

Questi Termini di Utilizzo (“Termini”) regolano l'uso di Inventus App fornita da BBASHI GmbH (“noi”, “nostro”). Utilizzando l'app, accetti di essere vincolato da questi Termini.

1. Concessione della licenza
Ti concediamo una licenza limitata, non esclusiva e non trasferibile per utilizzare Inventus App a fini personali e non commerciali in conformità a questi Termini e alle condizioni dell'App Store.

2. Abbonamento a rinnovo automatico
Nome e durata dell'abbonamento: offriamo un abbonamento con rinnovo automatico denominato "Inventus Premium" per 1 anno.Prezzo e fatturazione: L'abbonamento costa 4,99 $ (più eventuali tasse applicabili). Il tuo account iTunes verrà addebitato al momento della conferma dell'acquisto e al rinnovo.
Rinnovo automatico: Gli abbonamenti si rinnovano automaticamente, a meno che non vengano disattivati almeno 24 ore prima della fine del periodo in corso. Il tuo account sarà addebitato per il rinnovo entro 24 ore prima della fine del periodo, allo stesso prezzo salvo diversa indicazione.
Gestione degli abbonamenti: Puoi gestire o annullare il tuo abbonamento dalle impostazioni del tuo account iTunes in qualsiasi momento.
Prove gratuite (se applicabile): Se offriamo una prova gratuita, la parte non utilizzata verrà persa al momento dell'acquisto di un abbonamento.

3. Conduzione dell'utente
Accetti di non utilizzare Inventus App per scopi illeciti o dannosi e di rispettare tutte le leggi applicabili a livello locale, regionale, nazionale e internazionale.

4. Proprietà intellettuale
Tutti i diritti, titoli e interessi relativi a Inventus App, inclusi contenuti, funzionalità e marchi, appartengono a BBASHI GmbH o ai suoi concédenti di licenza. Non sei autorizzato a modificare, ridistribuire o creare opere derivate basate sull'app.

5. Privacy
L'uso di Inventus App è soggetto anche alla nostra politica di privacy, disponibile sul nostro sito web.

6. Esclusione di garanzie
Inventus App viene fornita “così com'è”. Decliniamo ogni garanzia, espressa o implicita, inclusa quella di idoneità a uno scopo particolare.

7. Limitazione di responsabilità
Nella misura consentita dalla legge, BBASHI GmbH non sarà responsabile dei danni indiretti, incidentali o consequenziali derivanti dall'uso o dall'incapacità di utilizzare Inventus App.

8. Modifiche e cessazione
Possiamo modificare o interrompere Inventus App in qualsiasi momento. L'uso continuato dopo l'entrata in vigore delle modifiche costituisce accettazione.
 
9. Diritto applicabile
Questi Termini sono regolati dalle leggi di [Il tuo paese], senza tenere conto dei principi di conflitto di leggi.

10. Contattaci
Per eventuali domande su questi Termini, contattaci:
BBASHI GmbH / Inventus App
contact@bbashi.com`,
      ourHeadquarters: "Our Headquarters",
      address: "Loëstrasse 37A, 7000 Chur",
      headquartersAlt: "Headquarters Image",
      contact: "Contact",
      privacy: "Privacy",
      privacyContent:
        "At BBASHI, your privacy is our priority. This applies to our Inventus App and all information provided on our website. We do not collect, store, or share any personal data through Inventus App or on this site. All data remains solely on your device and is not transmitted to our servers. We also use cookies solely to enhance your browsing experience and ensure our site functions properly. By continuing to use our website, you consent to the use of cookies as described in our policy.",
      cookieButtonText: "I Understand",
      cookieBannerText: "This website uses cookies to enhance your browsing experience."
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
