// src/CookieConsentContext.js
import React, { createContext, useState } from 'react';

export const CookieConsentContext = createContext({
  accepted: false,
  setAccepted: () => {},
});

export const CookieConsentProvider = ({ children }) => {
  const [accepted, setAccepted] = useState(false);

  return (
    <CookieConsentContext.Provider value={{ accepted, setAccepted }}>
      {children}
    </CookieConsentContext.Provider>
  );
};
