// src/pages/InventusApp.js
import React from 'react';
import { Container, Grid, Typography, Link as MuiLink, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledBackLink = styled(MuiLink)({
  fontWeight: 'bold',
  fontSize: '0.8rem',
  display: 'block',
  textAlign: 'center',
  marginBottom: '2rem',
  textDecoration: 'none',
  color: '#ADB5BD',
  '&:hover': { color: '#606C38' },
});

const InventusContainer = styled(Container)({
  marginTop: '2rem',
  marginBottom: '80px',
});

const ResponsiveInventusImage = styled('img')(({ theme }) => ({
  height: '270px',
  objectFit: 'contain',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    height: '250px',
  },
}));

const InventusApp = () => {
  const { t } = useTranslation();

  return (
    <InventusContainer maxWidth="md">
      {/* Back Link - goes back to /apps */}
      <Box sx={{ textAlign: 'center', mt: 2, mb: 4 }}>
        <StyledBackLink component={Link} to="/apps">
          {t('back')}
        </StyledBackLink>
      </Box>
      <Grid container spacing={4}>
        {/* Left Column: Inventus App Image */}
        <Grid item xs={12} md={6}>
          <ResponsiveInventusImage src="apps.png" alt={t('inventusAppAlt')} />
        </Grid>
        {/* Right Column: Inventus App Intro Text */}
        <Grid item xs={12} md={6}>
          <Typography variant="caption" sx={{ fontWeight: 'bold', mb: 2, fontSize: '0.8rem' }}>
            {t('inventusApp')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, fontSize: '0.8rem' }}>
            {t('inventusAppDesc')}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, fontSize: '0.8rem' }}>
            {t('availableSoonOn')}{' '}
            <img
              src="/appstore.png"
              alt={t('appStoreAlt')}
              style={{ verticalAlign: 'middle', height: '20px', marginLeft: '4px' }}
            />
          </Typography>
          <MuiLink
            component={Link}
            to="/terms"
            sx={{
              textDecoration: 'none',
              fontWeight: 'bold',
              fontSize: '0.8rem',
              color: '#606C38',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            {t('termsAndConditionsLink')}
          </MuiLink>
        </Grid>
      </Grid>
    </InventusContainer>
  );
};

export default InventusApp;
