// src/pages/ContactPage.js
import React from 'react';
import { Container, Grid, Typography, Link as MuiLink, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, keyframes } from '@mui/material/styles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ImageContainerWithHover = styled('div')({
  textAlign: 'center',
  cursor: 'pointer',
  // Removed any scaling effect on hover.
  '&:hover img': {
    position: 'relative',
    zIndex: 10,
  },
  '&:hover .hover-text': {
    opacity: 1,
  },
});

const ImageWrapper = styled('div')({
  height: '350px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'visible', // allow the image to display fully
});

const ResponsiveImage = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  objectFit: 'contain',
  transition: 'none',
  animation: `${fadeIn} 1s ease forwards`,
});

// Constrain the hover text to a centered block.
const HoverText = styled('div')({
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  fontSize: '0.8rem',
  marginTop: '20px',
  textAlign: 'center',
  padding: '0 10px',
  maxWidth: '300px',
  marginLeft: 'auto',
  marginRight: 'auto',
});

// Reusable ContactLink component for the mail icon and text.
const ContactLink = (props) => (
  <MuiLink
    href="mailto:contact@bbashi.com"
    sx={{
      textDecoration: 'none',
      color: 'inherit',
      display: 'inline-flex',
      alignItems: 'center',
      '&:hover': { color: '#606C38' },
    }}
    {...props}
  >
    <MailOutlineIcon sx={{ verticalAlign: 'middle', fontSize: '1.2rem', mr: 0.5 }} />
    <Typography variant="caption" component="span">
      contact@bbashi.com
    </Typography>
  </MuiLink>
);

// BackLink styled component.
const BackLink = styled(MuiLink)({
  fontWeight: 'bold',
  fontSize: '0.8rem',
  display: 'inline-block',
  textAlign: 'center',
  padding: '10px 0',
  textDecoration: 'none',
  color: '#ADB5BD',
  '&:hover': { color: '#606C38' },
});

const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <Container style={{ marginTop: '1.4rem', marginBottom: '80px' }}>
      {/* Back Link Container */}
      <Box sx={{ textAlign: 'center', mt: 2, mb: 4 }}>
        <BackLink component={Link} to="/">
          {t('back')}
        </BackLink>
      </Box>
      <Grid container spacing={6} justifyContent="space-evenly" alignItems="center">
        <Grid item xs={12} sm={8} md={6}>
          <ImageContainerWithHover>
            <ImageWrapper>
              <ResponsiveImage src="/hq.png" alt={t('headquartersAlt') || "Headquarters"} />
            </ImageWrapper>
            <HoverText className="hover-text">
              <Typography variant="caption" style={{ fontWeight: 'bold', display: 'block' }}>
                {t('ourHeadquarters')}
              </Typography>
              <br />
              <Typography variant="caption" style={{ display: 'block' }}>
                <MuiLink
                  href="https://maps.app.goo.gl/jK3RZDe22uEpfUQy6"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': { color: '#606C38' },
                  }}
                >
                  {t('address')}
                </MuiLink>
                <br /><br />
                <ContactLink />
              </Typography>
            </HoverText>
          </ImageContainerWithHover>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactPage;
