// src/pages/PrivacyPage.js
import React from 'react';
import { Container, Typography, Link as MuiLink, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PrivacyPage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="sm" sx={{ mt: { xs: '2rem', md: '1.8rem' }, mb: 4, px: 2 }}>
      {/* Back Link */}
      <Box sx={{ textAlign: 'center', mb: 7 }}>
        <MuiLink
          component={Link}
          to="/"
          sx={{
            fontWeight: 'bold',
            fontSize: '0.8rem',
            textDecoration: 'none',
            color: '#ADB5BD',
            '&:hover': { color: '#606C38' },
          }}
        >
          {t('back')}
        </MuiLink>
      </Box>
      <Typography variant="body2" align="left" sx={{ fontSize: '0.8rem', lineHeight: 1.5 }}>
        {t('privacyContent')}
      </Typography>
    </Container>
  );
};

export default PrivacyPage;
