// src/pages/AppsPage.js
import React, { useState } from 'react';
import { Container, Grid, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, keyframes } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ImageContainerWithHover = styled('div')({
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'opacity 0.3s ease-in-out',
  '& .hover-text': {
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    fontSize: '0.8rem',
    marginTop: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '0 10px',
    maxWidth: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '&:hover .hover-text': {
    opacity: 1,
  },
});

const ImageWrapper = styled('div')({
  height: '270px', // matching Home page apps image size
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'visible',
});

const ResponsiveImage = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  objectFit: 'contain',
  transition: 'none', // no scaling on hover
  animation: `${fadeIn} 1s ease forwards`,
});

// Reusable ContactLink with mail icon.
const ContactLink = (props) => (
  <MuiLink
    href="mailto:contact@bbashi.com"
    sx={{
      textDecoration: 'none',
      color: 'inherit',
      display: 'inline-flex',
      alignItems: 'center',
      '&:hover': { color: '#606C38' },
    }}
    {...props}
  >
    <MailOutlineIcon sx={{ verticalAlign: 'middle', fontSize: '1.2rem', mr: 0.5 }} />
    <Typography variant="caption" component="span">
      {props.children || ''}
    </Typography>
  </MuiLink>
);

const AppsPage = () => {
  const { t } = useTranslation();
  // hovered: 0 for Inventus App section, 1 for App Dev Service, or null.
  const [hovered, setHovered] = useState(null);

  return (
    <Container sx={{ mt: { xs: '2rem', md: '2rem' }, mb: '80px' }}>
      {/* Back Link placed between header and images */}
      <MuiLink
        component={Link}
        to="/"
        sx={{
          fontWeight: 'bold',
          fontSize: '0.8rem',
          display: 'block',
          textAlign: 'center',
          mb: 9,
          textDecoration: 'none',
          color: '#ADB5BD',
          '&:hover': { color: '#606C38' },
        }}
      >
        {t('back')}
      </MuiLink>
      <Grid container spacing={2} justifyContent="space-evenly" alignItems="center">
        {/* Inventus App Showcase (now not linking to /inventusapp) */}
        <Grid item xs={12} sm={6} md={5}>
          <div
            onMouseEnter={() => setHovered(0)}
            onMouseLeave={() => setHovered(null)}
            style={{
              opacity: hovered !== null && hovered !== 0 ? 0.5 : 1,
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            {/* Removed the Link wrapper */}
            <ImageContainerWithHover>
              <ImageWrapper>
                <ResponsiveImage src="/apps.png" alt={t('inventusAppAlt')} />
              </ImageWrapper>
              <div className="hover-text">
                <br />
                <Typography variant="caption" style={{ display: 'block', fontWeight: 'bold' }}>
                  {t('inventusApp')}
                </Typography>
                <br />
                <Typography variant="caption" style={{ display: 'block' }}>
                  {t('inventusAppDesc')}
                  <br /><br />
                  {t('availableSoonOn')}{' '}
                  <a
  href="https://apps.apple.com/ch/app/inventus-app/id6739474963?l=en-GB"
  target="_blank"
  rel="noopener noreferrer"
>
  <img
    src="/appstore.png"
    alt={t('appStoreAlt')}
    style={{
      verticalAlign: 'middle',
      height: '20px',
      marginLeft: '4px',
    }}
  />
</a>

                  <br /><br />
                  <MuiLink
                    component={Link}
                    to="/terms"
                    sx={{
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      fontSize: '0.8rem',
                      color: '#606C38',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    {t('termsAndConditionsLink')}
                  </MuiLink>
                </Typography>
              </div>
            </ImageContainerWithHover>
          </div>
        </Grid>
        {/* App Development Service Showcase remains unchanged */}
        <Grid item xs={12} sm={6} md={4}>
          <div
            onMouseEnter={() => setHovered(1)}
            onMouseLeave={() => setHovered(null)}
            style={{
              opacity: hovered !== null && hovered !== 1 ? 0.5 : 1,
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            <ImageContainerWithHover>
              <ImageWrapper>
                <ResponsiveImage src="/devsupport.png" alt={t('appDevServiceAlt')} />
              </ImageWrapper>
              <div className="hover-text">
                <Typography variant="caption" style={{ display: 'block', fontWeight: 'bold' }}>
                  {t('appDevService')}
                </Typography>
                <br />
                <Typography variant="caption" style={{ display: 'block' }}>
                  {t('appDevServiceDesc')}
                  <br /><br />
                  <ContactLink>{t('contactUsForPricing')}</ContactLink>
                </Typography>
              </div>
            </ImageContainerWithHover>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppsPage;
